import type React from "react";
import { createContext, useState, useContext } from "react";

// Define the context type
interface PageContextType {
	selectedPageId: string | null;
	setSelectedPageId: (id: string) => void;
	selectedPageContent: string | null;
	setSelectedPageContent: (id: string) => void;
	headerLogo: string | null;
	setHeaderLogo: (id: string) => void;
	userAvatar: string | null;
	setUserAvatar: (id: string) => void;
}

// Create context
const PageContext = createContext<PageContextType | undefined>(undefined);

// Provider component
export const PageProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [selectedPageId, setSelectedPageId] = useState<string | null>(
		localStorage.getItem("selectedPageId") || null,
	);
	const [selectedPageContent, setSelectedPageContent] = useState<string | null>(
		null,
	);
	const [headerLogo, setHeaderLogo] = useState<string | null>(null);
	const [userAvatar, setUserAvatar] = useState<string | null>(null);

	return (
		<PageContext.Provider
			value={{
				selectedPageId,
				setSelectedPageId,
				selectedPageContent,
				setSelectedPageContent,
				headerLogo,
				setHeaderLogo,
				userAvatar,
				setUserAvatar,
			}}
		>
			{children}
		</PageContext.Provider>
	);
};

// Custom hook to use the context
export const usePageContext = () => {
	const context = useContext(PageContext);
	if (!context) {
		throw new Error("usePageContext must be used within a PageProvider");
	}
	return context;
};
