import type React from "react";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Content from "./components/Content";
import { Box, CssBaseline } from "@mui/material";
import { PageProvider, usePageContext } from "./context/PageContext";
import { decryptObject } from "./services/decryption";
import { useAuth } from "./context/AuthContext";
import { isWithinCurrentHour } from "./services/dateTime";

const App: React.FC = () => {
	const { setIsAuthenticated, setUserName } = useAuth();
	const [loading, setLoading] = useState(true);
	const { setHeaderLogo, setUserAvatar, setSelectedPageId } = usePageContext();

	useEffect(() => {
		let token!: string | null;
		let pageId: string | null;

		const urlParams = new URLSearchParams(window.location.search);
		token = urlParams.get("token");
		pageId = urlParams.get("pageId");
		// console.log(`pageId: ${pageId}`);
		if (!token) {
			token = localStorage.getItem("rooster-support-token");
		}

		if (token) {
			const decryptedToken = decryptObject(token);
			const { timestamp, url, email, name, headerLogo, userAvatar } =
				decryptedToken;

			const tokenExpired = isWithinCurrentHour(timestamp);
			if (!tokenExpired) {
				if (localStorage.getItem("rooster-support-token")) {
					localStorage.removeItem("rooster-support-token");
				}
				const redirectURL: string = `${url}?token=${encodeURIComponent(token)}`;
				window.location.href = redirectURL;
			}
			setIsAuthenticated(isWithinCurrentHour(timestamp));
			setHeaderLogo(headerLogo);
			setUserAvatar(userAvatar);
			setUserName(name);
			setLoading(false);
			localStorage.setItem("rooster-support-token", token);

			window.history.replaceState({}, "", "/");
		} else {
			setIsAuthenticated(false);
			setLoading(false);
		}
		if (pageId) {
			// console.log(pageId);
			localStorage.setItem("selectedPageId", pageId);
			setSelectedPageId(pageId);
			window.history.replaceState({}, "", "/");
		}
	}, [
		setIsAuthenticated,
		setHeaderLogo,
		setUserAvatar,
		setUserName,
		setSelectedPageId,
	]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<PageProvider>
			<Box
				sx={{
					display: "flex",
					height: "100vh",
				}}
			>
				<CssBaseline />
				<Header />
				<Sidebar />
				<Content />
			</Box>
		</PageProvider>
	);
};

export default App;
